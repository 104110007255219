<template>
    <div class="joinUs-content">
        <div class="joinUs-banner">
            <img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/joinUs/joinUs-banner@2x.png"/>
            <div class="joinUs-banner-title">
                <h4>加入我们</h4>
                <p>集专科医生学习，培训和交流为一体的综合平台</p>
            </div>
        </div>
        <div class="joinUs-main">
            <div class="joinUs-main-title"><strong>招聘职位</strong></div>
            <div class="joinUs-main-item" v-for="(item,index) in join_us" :key="item.id">
                <div class="joinUs-main-item-title">
                    <strong>{{ item.position }}</strong> <span v-for="(arr, i) in item.skill" :key="i">{{ arr }}</span>
                </div>
                <h4>岗位职责</h4>
                <p v-for="(p,i) in item.duty">{{ p }}</p>
                <h4>任职要求</h4>
                <p v-for="(p,i) in item.post">{{ p }}</p>
            </div>
            <div class="joinUs-contact">
                <span>联系我们or投简历至</span>
                <span><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/joinUs/weixin@2x.png"/>微信号：15817346174</span>
                <span><img src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/joinUs/youxiang@2x.png"/>邮箱：liucui@ysxxlm.com</span>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                join_us: []
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                this.$axios.post(this.apiUrl.join_us).then(res => {
                    this.join_us = res.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
